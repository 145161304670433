/* General Styles */
.app-container {
  display: flex;
  flex-direction: column;
}


@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
  }

  /* Hide the SideNav on smaller screens */
  #sidebar-wrapper {
    width: 100%;
    height: auto;
    position: relative;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }
  


  
  #sidebar-wrapper.show {
    transform: translateX(0);
  }

  .btn-toggle-sidebar {
    display: block;
  }
}

@media (min-width: 769px) {
  .app-container {
    flex-direction: row;
  }

  #sidebar-wrapper {
    width: 250px;
    height: 100vh;
    position: fixed;
    transform: translateX(0);
  }
}

/* Button to show/hide the sidebar (only visible on mobile) */
.btn-toggle-sidebar {
  display: none;
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 101;
  background: rgba(0, 0, 0, 0.1);
  border: 2px solid #007bff;
  color: #007bff;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-toggle-sidebar:hover {
  background-color: #007bff;
  color: white;
}

/* Modal styling */
.modal {
  max-width: 100%;
}

.modal-dialog {
  width: auto; /* Allows modal to adapt to content */
  margin: 1.75rem auto;
}

@media (max-width: 576px) {
  .modal-dialog {
    width: 90%;
    margin: 1rem auto;
  }
}


/* Main content */
.main-content {
  margin-left: 5px;
  margin-top: 0px;
  padding: 1rem;
  flex-grow: 1;
}


.content-wrapper {
  margin-left: 1px; /* Adjust this value to match your SideNav width */
  padding: 20px;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .content-wrapper {
    margin-left: 0;
    padding: 10px;
  }
}

.table-responsive {
  overflow-x: auto;
}

/* Optimize table for smaller screens */
@media (max-width: 992px) {
  .table th, .table td {
    white-space: nowrap;
    padding: 0.5rem;
  }
}

/* Ensure SideNav doesn't cause horizontal scroll */
.d-flex {
  overflow-x: hidden;
}