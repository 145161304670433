.dashboard-container {
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.dashboard-content {
  flex: 1;
  padding: 1.5rem;
  margin-left: 250px; /* Matches SideNav width */
  transition: margin-left 0.3s ease;
}

.dashboard-header {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  margin-bottom: 1.5rem;
}

.dashboard-card {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  color: white;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.card-icon {
  font-size: 2rem;
  margin-right: 1rem;
  opacity: 0.8;
}

.card-content {
  flex: 1;
}

.card-content h6 {
  margin: 0;
  font-size: 0.875rem;
  opacity: 0.8;
}

.card-content h2 {
  margin: 0.5rem 0 0;
  font-size: 1.75rem;
  font-weight: bold;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .dashboard-card {
    padding: 1.25rem;
  }
  
  .card-icon {
    font-size: 1.75rem;
  }
  
  .card-content h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 992px) {
  .dashboard-content {
    padding: 1rem;
  }
  
  .dashboard-header {
    padding: 1.25rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 0;
    padding: 1rem;
  }
  
  .dashboard-card {
    padding: 1rem;
  }
  
  .card-icon {
    font-size: 1.5rem;
  }
  
  .card-content h2 {
    font-size: 1.25rem;
  }
}

@media (max-width: 576px) {
  .dashboard-content {
    padding: 0.75rem;
  }
  
  .dashboard-header {
    padding: 1rem;
  }
  
  .dashboard-card {
    padding: 0.875rem;
  }
  
  .card-content h6 {
    font-size: 0.75rem;
  }
  
  .card-content h2 {
    font-size: 1.125rem;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .dashboard-container {
    background-color: #1a1a1a;
  }
  
  .dashboard-header {
    background: #2d2d2d;
    color: white;
  }
}