.table-container {
  padding: 1rem;
  width: 100%;
  overflow-x: auto;
}

.box-container {
  background: #f8f9fa;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.filters {
  gap: 1rem;
}

.filter-group {
  margin-bottom: 1rem;
}

.filter-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #495057;
}

.table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1rem;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.table {
  margin-bottom: 0;
  white-space: nowrap;
  min-width: 1000px; /* Ensure table doesn't get too cramped */
}

.table th {
  position: sticky;
  top: 0;
  background: #0d6efd;
  z-index: 1;
  white-space: nowrap;
  padding: 1rem;
}

.table td {
  vertical-align: middle;
  padding: 0.75rem;
}

.btn-group {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.user-count {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .filters {
    gap: 0.75rem;
  }
  
  .filter-group {
    margin-bottom: 0.75rem;
  }
}

@media (max-width: 992px) {
  .table-container {
    padding: 0.75rem;
  }
  
  .box-container {
    padding: 1rem !important;
  }
  
  .filter-group {
    flex: 0 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }
}

@media (max-width: 768px) {
  .table-container {
    padding: 0.5rem;
  }
  
  .filter-group {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .btn-group {
    flex-direction: column;
    width: 100%;
  }
  
  .btn-group .btn {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  
  .user-count {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .user-count h5 {
    font-size: 1rem;
  }
}

/* Custom scrollbar for better UX */
.table-wrapper::-webkit-scrollbar {
  height: 6px;
}

.table-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Swipe indicator for mobile */
@media (max-width: 768px) {
  .table-wrapper::after {
    content: '← Swipe to view more →';
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    color: #6c757d;
    animation: fadeInOut 2s infinite;
  }

  @keyframes fadeInOut {
    0%, 100% { opacity: 0.5; }
    50% { opacity: 1; }
  }
}