/* Adjust the modal's size */
.custom-modal .modal-dialog {
  max-width: 95%; /* Increase the width to 95% */
  height: 95%; /* Increase the height to 95% */
}



.custom-modal .modal-content {
  height: 85%; /* Ensure content takes the full height */
}


.custom-modal {
  max-width: 1400px; /* Double the default width (600px is typical) */
}

.custom-modal .modal-body {
  overflow-y: auto; /* Allows scrolling if the content exceeds the height */
}
