.sidenav-container {
  position: relative;
  height: 100vh;
}

.toggle-button {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 1000;
  display: none;
  padding: 0.5rem;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.sidebar {
  width: 250px;
  height: 100vh;
  background: #f8f9fa;
  border-right: 1px solid #dee2e6;
  transition: transform 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.sidebar-header {
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  background: #0d6efd;
  color: white;
}

.nav-links {
  flex: 1;
  overflow-y: auto;
  padding: 1rem 0;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  color: #212529;
  text-decoration: none;
  transition: background-color 0.2s;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
}

.nav-link:hover {
  background-color: #e9ecef;
}

.nav-link.active {
  background-color: #0d6efd;
  color: white;
}

.nav-link i {
  margin-right: 0.5rem;
  width: 20px;
}

.user-email {
  padding: 1rem;
  text-align: center;
  font-weight: bold;
  border-top: 1px solid #dee2e6;
}

.logout-button {
  margin-top: auto;
  color: #dc3545;
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
  .toggle-button {
    display: block;
  }

  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }
}

/* Ensure main content doesn't overlap with sidebar */
@media (min-width: 769px) {
  main {
    margin-left: 250px;
  }
}