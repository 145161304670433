.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Ensures two cards per row on wider screens */
    gap: 1rem;
    padding: 1rem; /* Optional: Adds padding around the grid */
  }
  
  .application-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    padding: 1rem;
  }
  
  .application-card:hover {
    transform: translateY(-5px);
  }
  