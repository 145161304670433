/* WebUserTableCard.css */
.user-cards-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  padding: 1rem;
}

.user-card {
  transition: transform 0.2s ease-in-out;
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.user-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 50px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-active {
  background-color: #d4edda;
  color: #155724;
}

.status-inactive {
  background-color: #f8d7da;
  color: #721c24;
}

.user-details {
  font-size: 0.875rem;
  color: #6c757d;
}

/* View toggle button styles */
.view-toggle-btn {
  background-color: white !important;
  color: #0d6efd !important;
  border: 1px solid white !important;
  transition: all 0.2s ease-in-out;
  min-width: 140px;
}

.view-toggle-btn:hover {
  background-color: #f8f9fa !important;
  color: #0a58ca !important;
  border-color: #f8f9fa !important;
}

/* Header flex container adjustments */
.card-header .d-flex {
  gap: 0.5rem;
}

/* Responsive adjustments */
@media (max-width: 767.98px) {
  .table-responsive {
    margin: 0;
    padding: 0;
  }
  
  .table th, .table td {
    padding: 0.5rem;
  }
  
  .container-fluid {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .card-header .d-flex {
    justify-content: center;
    text-align: center;
  }

  .view-toggle-btn {
    width: 100%;
    margin-top: 0.5rem;
  }
}

/* Modal adjustments for mobile */
@media (max-width: 575.98px) {
  .modal-dialog {
    margin: 0.5rem;
  }
  
  .modal-body {
    padding: 1rem;
  }
  
  .modal-footer {
    padding: 0.75rem;
  }
}

/* Toast adjustments for mobile */
@media (max-width: 575.98px) {
  .Toastify__toast-container {
    padding: 0;
    width: 100vw;
  }
  
  .Toastify__toast {
    margin-bottom: 0.5rem;
    border-radius: 0;
  }
}

/* Button adjustments */
.btn {
  white-space: nowrap;
}

.gap-2 {
  gap: 0.5rem !important;
}