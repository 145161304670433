/* src/BlogPostTable.css */

/* General table styling */
.table th, .table td {
  padding: 0.5rem; /* Adjust the padding to reduce row height */
}

.table th {
  font-size: 0.875rem; /* Adjust font size if needed */
  background-color: #007bff; /* Set the background color of the header */
  color: #fff; /* Set the text color in the header */
  text-align: center; /* Center-align text in header cells */
}






.table td {
  background-color: #f8f9fa; /* Set the background color for table cells */
}

/* Add custom color to header cells */
.table th {
  background-color: #2684e9; /* Header background color */
  color: #ffffff; /* Header text color */
}

.post-image {
  width: 100px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers the area */
}

.table td img {
  max-width: 100px; /* Ensure images are consistently sized */
  height: auto;
}

.table td button {
  margin-right: 0.5rem; /* Adjust spacing between buttons */
}

/* Set specific widths for columns */
.table .title-column {
  width: 30%; /* Adjust the width as needed */
}


.fixed-width {
  width: 2%;
}


.table .description-column {
  width: 40%; /* Adjust the width as needed */
}

.table .image-column {
  width: 20%; /* Adjust the width as needed */
}

.table .actions-column {
  width: 10%; /* Adjust the width as needed */
}

/* Add this CSS to your stylesheet or CSS module */
.table-dark-border {
  border-collapse: collapse;
}

.table-dark-border th,
.table-dark-border td {
  border: 1px solid #333; /* Dark border color */
}

.form-label {
  font-weight: bold;
  font-size: 1.2rem; /* Increase the size by 2 units (20% larger than the default size) */
}






/* Responsive Design */
@media (max-width: 768px) {
  .table .title-column,
  .table .description-column,
  .table .image-column,
  .table .actions-column {
    width: auto; /* Adjust column width for smaller screens */
    display: block; /* Stack columns vertically */
  }
}
