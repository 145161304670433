/* Make the layout adjust for smaller screens */
.job-management {
    display: flex;
    flex-direction: column;
  }
  
  .main-content {
    flex-grow: 1;
    margin-left: 5px;
  }
  
  @media (max-width: 768px) {
    .job-management {
      flex-direction: column;
    }
  
    .main-content {
      margin-left: 0;
      width: 100%;
      padding: 1rem;
    }
  
    /* Hide the SideNav on smaller screens and toggle with button */
    #sidebar-wrapper {
      width: 100%;
      height: auto;
      position: relative;
      transform: translateX(-100%);
      transition: transform 0.3s ease;
    }
  
    #sidebar-wrapper.show {
      transform: translateX(0);
    }
  
    .btn-toggle-sidebar {
      display: block;
    }
  }
  
  @media (min-width: 769px) {
    .job-management {
      flex-direction: row;
    }
  
    #sidebar-wrapper {
      width: 250px;
      height: 100vh;
      position: fixed;
      transform: translateX(0);
    }
  }
  
  /* Button to show/hide the sidebar on mobile */
  .btn-toggle-sidebar {
    display: none;
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 101;
    background: rgba(0, 0, 0, 0.1);
    border: 2px solid #007bff;
    color: #007bff;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .btn-toggle-sidebar:hover {
    background-color: #007bff;
    color: white;
  }
  
  /* JobManagement Card Styling */
  .job-management-card {
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  }
  