.app-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.sidebar {
  width: 250px;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  margin-left: 250px; /* Offset for sidebar */
}

@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
  }

  .main-content {
    margin-left: 0;
    padding: 15px;
  }
}
